import '../styles.scss';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { userViewStyles } from './material-styles';
import { errorToast } from '../../../utils/notifications';

const botName = '@SEARCH2UA_bot';

const message = `Шановний користувач, Ви переведені в захищений бот.
1. Перейдіть у бот ${botName}.
2. Перевірте стан профілю.
3. Натисніть на кнопку інструкція в меню бота та ознайомтеся з нею перед початком роботи.
4. Працюйте тільки в захищеному боті ${botName}`;

const TranslateUser = ({ isTranslateUser, setIsTranslateUser, translatedUser }) => {
  const classes = userViewStyles();
  const [inputValue, setInputValue] = useState(message);

  const handleTranslate = () => {
    if (!inputValue.trim()) {
      return errorToast('This field is require!');
    }
    translatedUser(inputValue);
    setIsTranslateUser(false);
  };

  const handleCancel = () => {
    setIsTranslateUser(false);
    setInputValue(message);
  };

  const handleInput = ({ target: { value } }) => {
    setInputValue(value);
  };

  return (
    <>
      {isTranslateUser && (
        <div className="button-close-bot">
          <div className="title3">Перевести пользователя</div>
          <TextField
            className={classes.textField}
            label="Message"
            multiline
            rowsMax={10}
            value={inputValue}
            onChange={handleInput}
            variant="filled"
          />

          <div className="user-info-button-wrapper">
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              onClick={handleTranslate}
            >
              Перевести
            </Button>

            <Button
              className={classes.btn}
              variant="contained"
              color="secondary"
              onClick={handleCancel}
            >
              Отменить
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export { TranslateUser };
